import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from "./Footer.module.scss";
import LinkedInIcon from "../../assets/socials/linkedin.svg";
import FacebookIcon from "../../assets/socials/facebook.svg";
import YoutubeIcon from "../../assets/socials/youtube.svg";
import { Socials } from '../../types';
import { Clickable } from '../Clickable/Clickable';

interface FooterProps {
	socials: Socials;
}

export const Footer: React.FC<FooterProps> = ({ socials }) => {
	const data: {
		file: {
			childImageSharp: {
				fluid: {
					src: string
				}
			}
		},
	} = useStaticQuery(graphql`
	  query {
		  file(relativePath: { eq: "wizyter-logo-color.png" }) {
	      childImageSharp {
	        fluid(quality: 100) {
	          ...GatsbyImageSharpFluid
	        }
	      }
	    }
	  }
	`);

	const {
		file: { childImageSharp: { fluid } }
	} = data;

	return (
		<footer className={styles.footer}>
			<div className="container">
				<div className={styles.container}>
					<img src={fluid.src} className={styles.logo} />
					<div className={styles.socials}>
						<a href={socials.linkedIn} target="_blank">
							<Clickable>
								<LinkedInIcon />
							</Clickable>
						</a>
						<a href={socials.facebook} target="_blank">
							<Clickable>
								<FacebookIcon />
							</Clickable>
						</a>
						<a href={socials.youtube} target="_blank">
							<Clickable>
								<YoutubeIcon />
							</Clickable>
						</a>
					</div>
				</div>
				<p className={styles.copyright}>Copyright {new Date().getFullYear()} Wizyter</p>
			</div>
		</footer>
	)
}