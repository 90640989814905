import React from 'react';
import styles from "./GoToAppButton.module.scss";

interface GotoAppButtonProps {
	className?: string;
	inverted?: boolean;
}

export const GoToAppButton: React.FC<GotoAppButtonProps> = ({ children, className, inverted }) => {
	let buttonClassName = "button";
	let anchorClassName = styles.anchor;

	if (className) anchorClassName += ` ${className}`;
	if (inverted) buttonClassName += ` inverted`;

	return (
		<a href={`${process.env.GATSBY_APP_DOMAIN}/register`} className={anchorClassName}>
			<button className={buttonClassName}>
				{children}
			</button>
		</a>
	)
}