import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { GoToAppButton } from '../GoToAppButton/GoToAppButton';
import styles from "./Header.module.scss";
import SignInIcon from "../../assets/sign-in.svg";

interface HeaderProps {
	withLoginButton?: boolean;
	withRegisterButton?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ withLoginButton = false, withRegisterButton = true }) => {
	const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "wizyter-logo-color.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
	const {
		file: {
			childImageSharp: { fluid },
		},
	} = data;

	return (
		<header className={styles.header}>
			<div className={`container ${styles.container}`}>
				<img
					className={styles.logo}
					src={fluid.src}
				/>
				<div className={styles.buttonsContainer}>
					{withRegisterButton &&
						(
							<GoToAppButton className={styles.registerButton}>
								Załóż darmowe konto
							</GoToAppButton>
						)
					}
					{
						withLoginButton && (
							<a href={`${process.env.GATSBY_APP_DOMAIN}/login`}>
								<button className={styles.loginButton}>
									<SignInIcon />
									<span>
										Logowanie
									</span>
								</button>
							</a>
						)
					}
				</div>
			</div>
		</header>
	)
}