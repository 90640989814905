import React, { useEffect, useRef, useState } from 'react';
import styles from "./Clickable.module.scss";

interface ClickableProps {

}

export const RIPPLE_ANIMATION_TIME = 300;

export const Clickable: React.FC<ClickableProps> = ({ children }) => {
	const ref = useRef<null | HTMLDivElement>(null);
	const clickableRef = useRef<null | HTMLDivElement>(null);

	const [pos, setPos] = useState({ top: 0, left: 0 })
	const [clickPos, setClickPos] = useState({ top: 0, left: 0 })
	const [ripple, setRipple] = useState(false)

	useEffect(() => {
		if (ref.current) {
			setPos({ top: ref.current.clientHeight / 2, left: ref.current.clientWidth / 2 })
		}
	}, [ref.current])

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			setClickPos({
				top: e.offsetY - 5,
				left: e.offsetX - 5
			});
			setRipple(true);
		}

		clickableRef.current.addEventListener('mousedown', handleClick);

		return () => {
			clickableRef.current.removeEventListener('mousedown', handleClick);
		}
	}, [clickableRef.current])

	useEffect(() => {
		let timeout;

		if (ripple) {
			timeout = setTimeout(() => setRipple(false), RIPPLE_ANIMATION_TIME)
		}

		return () => {
			if (timeout) clearTimeout(timeout)
		}
	}, [ripple])

	return (
		<div className={styles.clickable} ref={clickableRef}>
			<span style={{ top: pos.top, left: pos.left, overflow: 'hidden' }}>
				{ripple &&
					<span
						className={styles.ripple}
						style={{
							top: clickPos.top,
							left: clickPos.left,
							animationDuration: `${RIPPLE_ANIMATION_TIME}ms`
						}}
					/>
				}
			</span>
			<div ref={ref}>
				{children}
			</div>
		</div>
	)
}